.customisable-cwf {
	margin-bottom: 30px;

	.row {
		margin: 0 -15px !important;
	}

	@media only screen and (min-width: 992px) {
		margin-bottom: 50px;
	}

	&__header {
		margin-bottom: 40px;
		padding-left: 15px;
		padding-right: 15px;

		@media only screen and (min-width: 992px) {
			margin-bottom: 65px;
		}
	}

	&__horizontal-line {
		background-color: $grey4;
		height: 1px;
		opacity: 0.25;
		width: 100%;

		&.mb {
			margin-bottom: 40px;
		}
	}

	&__filters {
		background-color: $grey4;
		color: $white;
		font-family: $secondary_font;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 30px;

		@media screen and (min-width: 500px) {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}

		@media only screen and (min-width: 992px) {
			margin-bottom: 50px;
		}

		&--filter-by {
			display: none;

			@media only screen and (min-width: 900px) {
				display: block;
				font-size: 16px;
				color: $white;
				font-family: $secondary_font;
			}
		}

		&--holder {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			@media screen and (min-width: 500px) {
				flex-wrap: nowrap;
			}
		}
	}

	&__filter {
		cursor: pointer;
		display: inline-block;
		min-width: 50%;
		padding: 18px 15px;
		position: relative;
		font-family: $primary_font;

		@media screen and (min-width: 500px) {
			min-width: 110px;
		}

		@media screen and (min-width: 700px) {
			min-width: 170px;
			padding: 18px 30px;
		}

		@media screen and (min-width: 900px) {
			padding: 18px 40px;
		}

		&:nth-of-type(1) {
			@media screen and (min-width: 500px) {
				border-bottom: 0;
				border-left: 0;
			}
		}

		&:nth-of-type(2) {
			@media screen and (min-width: 500px) {
				border-bottom: 0;
			}
		}

		&:nth-of-type(3) {
			@media screen and (min-width: 500px) {
				border-left: 0;
			}
		}

		&[data-selected]:not([data-selected=""]) {
			background-color: $btn_text_colour;
		}

		p,
		p span {
			font-family: $secondary_font;
			font-size: 16px;
			text-align: center;
			margin-bottom: 0;
			color: $white;

			@media screen and (min-width: 700px) {
				font-size: 18px;
			}
		}

		&#all {
			min-width: 50%;

			@media screen and (min-width: 500px) {
				min-width: 65px;
			}

			@media screen and (min-width: 700px) {
				min-width: 110px;
			}

			&.selected {
				background-color: $btn_text_colour;
			}
		}

		ul {
			background-color: $grey4;
			display: none;
			left: -1px;
			max-height: 300px;
			overflow: scroll;
			padding: 20px 15px 10px;
			position: absolute;
			top: 57px;
			width: calc(100% + 2px);
			z-index: 20;
			margin: 0;

			@media screen and (min-width: 700px) {
				padding: 20px 20px 10px;
			}
			@media screen and (min-width: 900px) {
				padding: 20px 25px 10px;
			}

			&.active {
				display: block;
			}

			li {
				cursor: pointer;
				font-family: $primary_font;
				color: $white;
				font-size: 14px;
				margin-bottom: 10px;
				transition: 0.2s;
				list-style: none;

				@media screen and (min-width: 500px) {
					font-size: 16px;
				}

				@media screen and (min-width: 700px) {
					font-size: 18px;
				}

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}

	&__tile {
		height: 230px;
		position: relative;
		margin-bottom: 30px;
		overflow: hidden;

		@media screen and (min-width: 900px) {
			height: 280px;
		}

		@media screen and (min-width: 1080px) {
			height: 330px;
		}

		&-image {
			background-position: center;
			background-size: cover;
			position: absolute;
			height: 120%;
			width: 120%;
			top: -10%;
			left: -10%;
			transition-property: all;
			transition-duration: 0.5s; 
			transition-timing-function: ease-in-out;
		}

		&:hover {
			cursor: pointer;

			.customisable-cwf__tile {
				&--overlay {
					opacity: 1;
 				}

				&-image {
					transform: scale(1.1); 
 				}
 			}

			a {
				color: $btn_text_colour;
 			}
		}

		&--content-holder {
			bottom: 20px;
			left: 20px;
			padding-right: 20px;
			position: absolute;
			z-index: 10;

			@media screen and (min-width: 900px) {
				bottom: 30px;
				left: 30px;
				padding-right: 30px;
			}

			h4 {
				color: $white;
				margin-bottom: 5px;
			}

			a {
				color: $white;

				&:after {
					content: url(../../images/graphics/right-arrow.svg);
					position: absolute;
					transform: translate(10px, 2px);
					transition: 0.2s;
				}

				&:hover {
					&:after {
						transform: translate(15px, 2px);
					}
				}
			}
		}

		&--overlay {
			background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
			height: 60%;
			bottom: 0;
			left: 0;
			opacity: 0.7;
			position: absolute;
			transition: 0.3s;
			width: 100%;
		}
	}

	.no-results {
		font-family: $secondary_font;
		font-size: 16px;

		@media screen and (min-width: 768px) {
			font-size: 18px;
 		}

		@media screen and (min-width: 1024px) {
			font-size: 19px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 20px;
 		}
 	}
}
