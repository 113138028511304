.customisable-banner {
	&__container {
		&--standard-width {
			max-width: $grid_width;
			padding: 0 30px;
			margin: 50px auto;

			@mixin screen-size-900 {
				margin: 70px auto;
			}

			@media screen and (min-width: 1080px) {
				margin: 90px auto;
			}

			@media screen and (min-width: 1200px) {
				margin: 105px auto;
			}
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		display: flex;
		position: relative;
		width: 100%;
		padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
		overflow: hidden;
		@media screen and (max-width: 600px) {
			padding-top: 100%;
		}
		
		img {
			position: absolute;
			top: 0;
			left: 0;
 			width: 100%;
			height: 100%;
			object-fit: cover;
 			transition: transform 0.5s ease-in-out;
			@media screen and (max-width: 600px) {
				height: 65vh;
			}
		}

		&-caption {
			color: $text_colour;
			font-family: $secondary_font;
			font-style: normal;
			font-weight: 400;
			line-height: 28px; /* 155.556% */
			font-size: 18px;
			margin-top: 20px;

			p {
				transition: transform 0.5s ease-in-out;
				font-family: $secondary_font;
			}

			@mixin screen-size-900 {
				font-size: 20px;
				margin-top: 16px;
			}

			.customisable-banner__container--full-width & {
				padding-left: 30px;
			}
		}
	}

	&__overlay {
		position: absolute;
		z-index: 2;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%);

		.col-12 {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		h1 {
			color: #fff;
			font-family: $primary_font;
			font-style: normal;
			font-weight: 400;
			text-transform: uppercase;
			font-size: 32px;
			line-height: 125%;
			letter-spacing: 1.28px;

			@media screen and (min-width: 700px) {
				font-size: 40px;
				letter-spacing: 1.8px;
			}

			@media screen and (min-width: 950px) {
				font-size: 50px;
				line-height: 120%;
				letter-spacing: 2.2px;
			}

			@media screen and (min-width: 1080px) {
				font-size: 65px;
				line-height: 115%;
				letter-spacing: 2.6px;
			}
		}

		p {
			color: #fff;
			font-family: $secondary_font;
			text-align: center;
			font-style: normal;
			max-width: 880px;
			font-weight: 400;
			font-size: 16px;
			line-height: 175%;
			margin-top: 12px;

			@media screen and (min-width: 700px) {
				margin-top: 18px;
			}

			@media screen and (min-width: 950px) {
				font-size: 18px;
				line-height: 170%;
				margin-top: 24px;
			}

			@media screen and (min-width: 1080px) {
				font-size: 20px;
				line-height: 160%;
				margin-top: 30px;
			}
		}
	}

	&__ctas {
		display: flex;
		flex-direction: column;
		margin-top: 40px;
		gap: 20px;

		@media screen and (min-width: 700px) {
			margin-top: 45px;
			gap: 30px;
		}

		@media screen and (min-width: 950px) {
			flex-direction: row;
			margin-top: 60px;
		}

		@media screen and (min-width: 1080px) {
			margin-top: 80px;
		}
	}

	&__link {
		text-decoration: none;
		&:hover {
			.customisable-banner__image {
				img {
					transform: scale(1.1);
				}

				&-caption {
					p {
						color: $text_colour !important;
					}
				}
			}
		}
	}
}
