$primary_font: 'Roboto';
$secondary_font: 'Lato';
$alt_font: 'Lato';

$grey1: #f2f0ed;
$grey2: #D6D1CA;
$grey3: #4D453E;
$grey4: #4D453E;
$white: #fff;
$aqua: #D6D1CA;
$orange: #4D453E;

$btn_bg_colour: $grey2;
$btn_text_colour: $orange;

$text_colour: $grey4;

$grid_width: 1280px;
